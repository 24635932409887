import { Box3, Mesh, Object3D, Sphere } from "three";

export const computeBoundingSphere = (scene: Object3D) => {
  // see issue #131 on gitlab for details
  const boundingSphere = new Sphere();
  const object3d = scene;
  const center = boundingSphere.center;
  const _box3A = new Box3();

  _box3A.makeEmpty();
  for (const childObj of object3d.children) {
    // find the center
    childObj.traverseVisible((object: Object3D) => {
      if (!(object as Mesh).isMesh) return;

      _box3A.expandByObject(object, true);
    });
  }

  _box3A.getCenter(center);

  boundingSphere.radius = _box3A.max.distanceTo(_box3A.min) / 2;
  return boundingSphere;
};
